<template>
  <div>
    <div
      class="search-options"
      v-if="searchFilter || countryFilter || typeFilter"
    >
      <div class="box-container">
        <div class="box-half" v-if="searchFilter">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              class="ml-n6"
            ></v-text-field>
          </v-card-title>
          <div
            class="grey--text text--darken-1 text-subtitle-2 pt-4"
            v-if="engagementFilter"
          >
            Direct Engagements
            <v-checkbox
              v-model="bcpiEngagement"
              dense
              class="mb-n5 engagement"
              background-color="transparent"
            >
              <template v-slot:label>
                <span class="ml-1" style="font-size: 14px;">BCPI</span>
              </template></v-checkbox
            >
            <v-checkbox
              v-model="govexEngagement"
              dense
              class="engagement"
              background-color="transparent"
            >
              <template v-slot:label>
                <span class="ml-1" style="font-size: 14px;">GovEx</span>
              </template></v-checkbox
            >
          </div>
        </div>
        <div class="box-quarter" v-if="typeFilter">
          <v-select
            class="pt-4 pl-16"
            v-model="type"
            :items="types"
            label="Types"
            outlined
          ></v-select>
        </div>
        <div class="box-quarter" v-if="countryFilter">
          <v-select
            class="pt-4 pl-16"
            v-model="country"
            :items="getCountries()"
            label="Country"
            outlined
          ></v-select>
          <v-switch
            v-if="partnersFilter"
            class="pl-16 mt-0"
            v-model="showOnlyPartners"
            label="Only show partners and funders"
            inset
          ></v-switch>
        </div>
        <div class="box-quarter">
          <v-select
            class="pt-4 pl-16"
            v-model="state"
            :items="getStates(country)"
            label="State"
            outlined
            :readonly="readOnly"
          ></v-select>
          <v-switch
            v-if="projectsFilter"
            class="pl-16 m-0"
            v-model="showOnlyProjects"
            label="Has projects"
            inset
          ></v-switch>
        </div>
      </div>
    </div>
    <div class="results-count">
      Showing <strong>{{ count }}</strong> Organization<span v-if="count != 1"
        >s</span
      >
    </div>
    <v-card>
      <v-data-table
        :headers="_headers"
        :items="filteredOrganizations"
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
        class="mb-16"
        multi-sort
        @pagination="getPageCount"
        :class="routeClass"
      >
        <template v-slot:item.name="{ item }">
          <a :href="`/contacts/organizations/${item.id}`">{{ item.name }}</a>
        </template>
        <template v-slot:item.type[1]="{ item }">
          <span v-if="item.type"> {{ item.type[1] }}</span>
          <span v-else>-</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<style>
/* Page CSS */
#search {
  position: relative;
  top: -14px;
}
.input-box {
  width: 90%;
}
label[for="search"] {
  height: 25px;
}

.engagement .v-input--selection-controls__input {
  width: 15px !important;
  height: 15px !important;
  background-color: white !important;
}

.engagement .v-input--selection-controls__ripple {
  width: 28px !important;
  height: 28px !important;
  left: -13px !important;
}

.route-organizations .v-data-table__mobile-row:nth-child(4),
.route-organizations .v-data-table__mobile-row:nth-child(5),
.route-organizations .v-data-table__mobile-row:nth-child(6) {
  display: none;
}
</style>
<script>
import pageCountMixin from "../mixins/pageCount";
import countryStateMixin from "../mixins/countries";
export default {
  props: {
    endPoint: {
      type: String,
      required: false,
      default: "contacts/organizations?ordering=name&limit=1000",
    },
    columns: {
      type: Array,
      required: false,
      default: () => [
        "Name",
        "Type",
        "Projects",
        "State",
        "Country",
        "Direct Engagement",
      ],
    },
    searchFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    countryFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    typeFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    projectsFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOnlyProjects: {
      type: Boolean,
      required: false,
      default: false,
    },
    partnersFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    engagementFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [pageCountMixin, countryStateMixin],
  components: {},
  data() {
    return {
      organizations: [],
      search: "",
      country: "",
      loading: true,
      state: "",
      type: "",
      readOnly: true,
      types: [
        "",
        "Corporation",
        "County",
        "County Department",
        "Municipality",
        "Municipal Department",
        "Nation",
        "National Department",
        "Non-profit or NGO",
        "Special Purpose Entity",
        "State",
        "State Department",
      ],
      govexEngagement: false,
      bcpiEngagement: false,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
          cellClass: "table-item table-link",
        },
        {
          text: "Type",
          value: "type[1]",
          sortable: true,
          cellClass: "table-item",
          filter: (value) => {
            if (!this.type) return true;
            return value === this.type;
          },
        },
        {
          text: "Projects",
          value: "project_count",
          sortable: true,
          cellClass: "table-item",
          filter: (value) => {
            if (!this.showOnlyProjects) return true;
            return value > 0;
          },
        },
        {
          text: "Total Projects",
          value: "project_count",
          sortable: true,
          cellClass: "table-item",
        },
        {
          text: "Active Projects",
          value: "active_project_count",
          sortable: true,
          cellClass: "table-item",
        },
        {
          text: "State",
          align: " d-none",
          value: "state_region",
          filter: (value) => {
            if (!this.state) return true;
            return (
              value === this.state ||
              value === this.getStateAbbrv(this.country, this.state)
            );
          },
        },
        {
          text: "Country",
          align: " d-none",
          value: "country_region",
          filter: (value) => {
            this.readOnly = this.country === "";
            this.state = "";
            if (!this.country) return true;
            return value !== "" && this.country.includes(value);
          },
        },
        {
          text: "Direct Engagement",
          align: " d-none",
          value: "center",
          filter: (value, search, item) => {
            if (!this.bcpiEngagement && !this.govexEngagement) return true;

            if (this.bcpiEngagement && this.govexEngagement) {
              return (
                item.centers?.includes("BCPI") &&
                item.centers?.includes("GovEx")
              );
            }

            if (this.bcpiEngagement && !this.govexEngagement) {
              return item.centers?.includes("BCPI");
            }

            if (this.govexEngagement && !this.bcpiEngagement) {
              return item.centers?.includes("GovEx");
            }

            return false;
          },
        },
      ],
      showOnlyPartners: false,
    };
  },

  created() {
    this.showOnlyPartners = this.$route.query.funders_and_partners === "true";
    this.type =
      this.$route.query.type === "municipality"
        ? (this.type = "Municipality")
        : "";
    this.getOrganizations();
  },
  computed: {
    filteredOrganizations() {
      if (!this.showOnlyPartners) {
        return this.organizations;
      }
      return this.organizations.filter(
        (org) => org.is_funder || org.is_partner
      );
    },
    _headers() {
      return this.headers.filter((item) => this.columns.includes(item.text));
    },
    routeClass() {
      return `route-${this.$route.name}`;
    },
  },
  watch: {
    organizations(currentValue) {
      this.$emit("organizations", currentValue);
    },
    beforeMount() {
      this.$emit("organizations", this.organizations);
    },
    showOnlyPartners(newVal) {
      console.log("showOnlyPartners changed:", newVal);
      this.$router
        .push({
          query: {
            ...this.$route.query,
            funders_and_partners: newVal ? "true" : "false",
          },
        })
        .catch((err) => {});
    },
    type(newVal) {
      this.$router.push({
        query: {
          ...this.$route.query,
          type: newVal || "",
        },
      });
    },
  },
  methods: {
    getOrganizations: async function() {
      let url = this.endPoint;
      while (url) {
        let response = await this.$api.get(url);
        this.organizations.push(...response.data.results);
        url = response.data.next;
      }
      this.loading = false;
    },

    testing(item) {
      return item.country_region ? "selectedColor" : "";
    },
  },
};
</script>
